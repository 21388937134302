@font-face {
  font-family: Gotham;
  src: url("/public/fonts/Gotham-Book.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Gotham;
  src: url("/public/fonts/Gotham-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url("/public/fonts/Gotham-Bold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: Gotham;
  src: url("/public/fonts/Gotham-Black.otf") format("opentype");
  font-weight: 700;
}


* {
  box-sizing: border-box;
  margin: 0;
  font-family: Gotham;

  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 32px;
  --spacing-xl: 40px;
  --spacing-xxl: 64;

  --black: #1d1d1d;
  --black-rgb: 29, 29, 29;

  --error: #ff3342;
  --error: #fd6370;
  --error-rgb: 255, 51, 66;

  --app-height: 100vh;

  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background-color: #fafafa;
}

a,
a:visited {
  color: var(--primary);
  text-decoration: none;
}

.step-container {
  z-index: 999;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: var(--app-height);
  height: 100%;
  padding: 20px 20px 40px 20px;
  overflow-y: auto;
}

.error,
.error * {
  font-size: 12px;
  color: var(--error);
}
